export class Vales {
  Id: number;
  Empleado: string;
  PuestoEmpleado: string;
  IdTipoVale: number;
  FechaPermiso: Date;
  HoraInicio: string;
  HoraFin: string;
  EstadoVale: string;
  EmpleadoAutoriza?: string;
  PuestoAutoriza?: string;
  FechaAutoriza?: Date;
  IdEstado: string;
  ObservacionAutoriza?: string;
  Funcionario: string;
  FechaRegistro: Date;
  EmpleadoAvala?: string;
  PuestoAvala?: string;
  FechaAvala?: Date;
  ObservacionAvala?: string;
}
